@media (max-width: $break-md - 1){
    .r-tabs-tab a, .r-tabs-accordion-title a{
        width: 80%;
    }
    #creation_compte_pro{
        .formulaire{
            padding: 26px;
        }
        #temoignages{
            margin-top: 50px;
        }
    }
}