@media (min-width: $break-md){
    #nouveautes{
        h5{
            position: relative;
            margin-bottom: 45px;
            &:before{
                content: " ";
                position: absolute;
                top: 32px;
                left: 0;
                right: 0;
                height: 4px;
                background-color: #ebebeb;
            }
            span{
                background-color: white;
                display: inline-block;
                padding: 0 30px;
                position: relative;
            }
        }
    }
    .produit{
        p{
            font-size: 15px;
        }
    }
    #footer_top{
        .coordonnees{
            padding: 0 0 30px 0;
        }
    }
    .r-tabs-panel{
        border: 2px solid #e8ebee;
    }
    .selection_cat{
        margin-bottom: 67px;
    }
    #responsiveTabsDemo{
        margin-top: 127px;
    }
    #main_menu{
        width: 371px;
    }
    .produit_style{
        .image{
            height: 330px;
        }
    }
    #meme_categorie{        
        .first{
            margin: 0;
        }
    }
    #cle_reussite{
        background-size: auto;
        padding: 190px 0 0 158px;
        .acc{
            p{
                background-color: white;
                padding: 10px 12px;
            }
        }
    }
}