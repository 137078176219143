@import 'common.scss';


/*** Required form field ***/
label:not(.form-check-label).required::before,
legend.required::before,
.input-group-prepend label.required::before {
        content: "*";
        margin-right: 5px;
        color: $pink;
}

/*** Category and products thumbs ***/
figure a {
    &:hover {
        font-weight: bold;
        text-decoration: none;
    }
}

/*** carousel slider indicator ***/
.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $pink;
}

// New 
#header {
    $header_h:70px;
    height: $header_h;
    background-color: #ffffff;
    .container{
        height: $header_h;
        position: relative;
    }
    .icon_user_container{
        position: absolute;
        top: 21px;
        right: 15px;        
        strong{
            color: #000000;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
        }
        span{
            color: #646464;
            font-size: 13px;
            letter-spacing: 0.26px;
            width: 100%;
            a {
                color: #646464;
            }
        }
    }
    .icon_burger{
        position: absolute;
        top: 17px;
        left: 15px;
    }
}

#logo{
    position: absolute;
    top: 20px;
    left: 89px;
    display: inline-block;
}

.icon_burger{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-burger.svg) no-repeat 0 0;
    width: 41px;
    height: 29px;
}

.icon_user{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-user.svg) no-repeat 0 0;
    width: 22px;
    height: 25px;
}

.icon_smartphone{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-smartphone.svg) no-repeat 0 0;
    width: 16px;
    height: 25px;
}

.icon_email{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-email.svg) no-repeat 0 0;
    width: 22px;
    height: 18px;
}

.icon_marqueur{
    display: inline-block;
    background-size: cover !important;
    background: transparent url(../images/icon-marqueur.svg) no-repeat 0 0;
    width: 13px;
    height: 17px;
}

.icon_experience{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-experience.svg) no-repeat 0 0;
    width: 32px;
    height: 32px;
}

.icon_madeinfrance{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-madeinfrance.svg) no-repeat 0 0;
    width: 21px;
    height: 41px;
}

.icon_garantie{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-garantie.svg) no-repeat 0 0;
    width: 26px;
    height: 32px;
}

.icon_securite{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-securite.svg) no-repeat 0 0;
    width: 25px;
    height: 32px;
}

.icon_rapidite{
    display: inline-block;
    background-size: cover;
    background: transparent url(../images/icon-rapidite.svg) no-repeat 0 0;
    width: 38px;
    height: 29px;
}

#particulier{
    line-height: 41px;
    height: 41px;
    padding-left: 26px;
    border-left: 1px solid #d8d8d8;
    position: absolute;
    right: 15px;
    top: 14px;
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.39px;
    text-transform: uppercase;
    i{
        margin-left: 17px;
    }
}

.table_container{
    clear: both;
    /*max-width: 100%;
    overflow-x: scroll;*/
}

#main_menu{
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 85%;
    background-color: white;
    padding: 13px 15px;
    box-shadow: 10px 10px 20px rgba(199, 205, 213, 0.55);
    display: none;
    
    #logo_menu{
        display: inline-block;
        top: 20px;
        left: 87px;
        position: absolute;
    }
    .liens_menu{
        display: inline-block;
        color: #000000;
        font-size: 18px;
        font-weight: 700;
        padding-left: 35px;
        margin: 15px 0;
        position: relative;
        i{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

#icon_fermer{
    font-size: 41px;
    color: black;
}

#actions{
    padding: 47px 0;
    .configurateur{
        h4{
            font-size: 25px;
            font-weight: 400;
            line-height: 1em;
            margin: 17px 0 15px;
        }
        p{
            color: #000000;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 33px;
        }
    }
    .bloc{
        height: 309px;
        background-color: #000000;
        margin-bottom: 35px;
        padding: 39px 28px 0 28px;
        text-align: center;
        a{
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.56px;
            text-transform: uppercase;
            display: inline-block;
            margin: 5px 0 0 0;
        }
        span{
            display: inline-block;
            color: #ffffff;
            font-size: 30px;
            font-weight: 400;
            line-height: 1.1em;
            text-align: left;
            background: transparent url("../images/sep_devis_bg.svg") no-repeat 0 0;
            padding: 59px 0 0 39px;
            margin-bottom: 59px;
            strong{
                font-size: 39px;
                font-weight: 400;
                display: inline-block;
                width: 100%;
            }
        }
        &.commande{
            background-color: $pink;
            span{
                background: transparent url("../images/sep_commande_bg.svg") no-repeat 0 0;
            }
        }
    }
}

.max_w{
    max-width: 100%;
    height: auto;
}

#nouveautes{
    h5{
        margin: 0 0 25px 0;
        text-align: center;
        color: #000000;
        font-size: 41px;
        font-weight: 400;
        line-height: 60px;
    }
}

.produit{
    margin-bottom: 50px;
    .image{
        position: relative;
        overflow: hidden;
        height: 194px;
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            transition: all ease 0.3s;
        }
        span{
            position: absolute;
            height: 33px;
            line-height: 33px;
            background-color: #ffffff;
            color: $pink;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0 10px;
            left: 0;
            bottom: 0;
            i{
                margin: 0 7px 0 0;
                position: relative;
                transform: translateY(-9px);
            }
        }
        &:hover{
            img{
                width: 110%;
            }
        }
    }
    h2{
        color: #000000;
        font-size: 17px;
        font-weight: 700;
        margin: 10px 0;
    }
    p{
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 22px;
    }
    a{
        margin-top: 5px;
    }
}

.bouton{
    display: inline-block;
    color: $pink;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.68px;
    text-transform: uppercase;
    border: 1px solid black;
    padding: 10px 20px 11px;
    position: relative;
    background: white;
    &:after{
        content: " ";
        height: 12px;
        border: 4px solid white;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
        width: 157px;
        background-color: #cfd4dc;
    }
    &:hover{
        text-decoration: none;
        background-color: $pink;
        color: white;
        border-color: $pink;
        &:after{
            border: 0;
            bottom: -3px;
            height: 4px;
            width: 149px;
        }
    }
    i{
        font-size: 19px;
        margin-right: 18px;
    }
}

#sav_news{
    margin-top: 76px;
    background-color: black;
    padding: 29px 0;
    .titre{
        display: inline-block;
        width: 100%;
        color: #ffffff;
        font-size: 26px;
        font-weight: 700;
        border-bottom: 1px solid $pink;
        padding: 5px 0 15px;
        margin: 0 0 20px 0;
    }
    a.tel{
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.6px;
        text-decoration: none;
    }
    a{
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;
        padding: 0 0 0 25px;
        position: relative;
        i{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    p{
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid $pink;
        margin: 21px 0;
        padding-bottom: 15px;
    }
}

.newsletter, .download {
    padding: 30px 0;
    h6{
        color: #ffffff;
        font-size: 26px;
        font-weight: 700;
    }
    .form_container{
        position: relative;
        margin: 30px 0;
        .input_text{
            width: 100%;;
            height: 52px;
            border-radius: 26px;
            border: 2px solid rgba(99, 111, 121, 0.25);
            background-color: #ffffff;
            color: black;
            font-family: "Century Gothic";
            font-size: 15px;
            padding-left: 33px;
            padding-right: 150px;
            &:focus{
                outline: none;
            }
        }
        button{
            border: 0;
            background: transparent;
            position: absolute;
            right: 15px;
            top: 16px;
            color: $pink;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 1.26px;
            text-transform: uppercase;
            i{
                font-size: 19px;
                margin-left: 11px;
            }
        }
    }
    label{
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
    }
}

#reassurance{
    padding: 25px 0;
    text-align: center;
    .icone{
        position: relative;
        height: 57px;
        i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    strong{
        color: #000000;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.69px;
        text-transform: uppercase;
        margin-bottom: 9px;
        display: inline-block;
        width: 100%;
    }
    p{
        color: #000000;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.29px;
        line-height: 19px;
    }
}

#footer_top{
    background-color: rgba(#d2d7de,0.5);
    padding: 49px 0 0 0;
    #logo_footer{
        margin: 0;
    }
    .coordonnees{
        padding-top: 44px;
        padding-bottom: 20px;
        .titre{
            display: inline-block;
            color: $pink;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.85px;
            text-transform: uppercase;
        }
        p{
            color: #000000;
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            margin: 9px 0px;
        }
    }
}

#slider_accueil{
    .slide{
        height: 360px;
        overflow: hidden;
        position: relative;
        img{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            min-width: 100%;
        }
    }
    .container{
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
        &:before{
            content: " ";
            position: absolute;
            top: 0;
            left: 15px;
            bottom: 0;
            background-color: white;
            width: 114px;
            z-index: 1;
        }
    }
    .texte{
        position: relative;
        z-index: 2;
        padding-left: 15px;
    }
    span{
        color: #000000;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 0.27px;
        text-transform: uppercase;
    }
    a{
        display: inline-block;
        color: #000000;
        font-size: 8px;
        line-height: 1.2em;
        font-weight: 700;
        letter-spacing: 0.24px;
        position: relative;
        padding-right: 20px;
        i{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            font-size: 19px;
        }
    }
    h1{
        color: $pink;
        font-size: 35px;
        font-weight: 700;
        line-height: 0.9em;
        margin: 18px 0 53px;
    }
}

.slider_container{
    position: relative;
}

.navSlider{
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 0;
    text-align: center;
}

.navSlider-dessous, .navSlider-dessous2{
    text-align: center;
    padding-top: 20px;
    margin-bottom: 60px;
}

.navigation_slider_base {
    display: block;
    width: 100%;
    button{
        background: transparent;
        border: 0 none;
    }
    .slick-arrow{
        z-index: 1;
        position: relative;
        font-size: 43px;
        font-weight: 400;
        color: $pink;
        &.left{
            float: left;
        }

        &.right{
            float: right;
        }
    }
}

.navigation_slider, .navigation_slider2 {
    height: 35px;
    position: relative;
    border-radius: 20px;
    border: 1px solid rgba(100, 100, 100, 0.41);
    background-color: #ffffff;
    display: inline-block;
    button{
        background: transparent;
        border: 0 none;
    }
    .slick-arrow{
        z-index: 1;
        position: absolute;
        top: 50%;
        font-size: 27px;
        font-weight: 400;
        color: $pink;
        transform: translateY(-50%);
        &.left{
            left: 17px;
        }
        &.right{
            right: 17px;
        }
    }
    .slick-dots{
        display: inline-block;
        position: relative;
        padding-left: 47px;
        padding-right: 47px;
        li{
            padding: 0;
            display: inline;
            position: relative;
            &:before{
                display: none;
            }
            button{
                position: relative;
                display: inline-block;
                text-indent: -9999px;
                width: 13px;
                height: 13px;
                padding: 0;
                background-color: #dbdbdb;
                border-radius: 50%;
                margin: 0 4px;
                margin-top: 10px;
                outline: 0 none !important;
            }
            &.slick-active{
                button{
                    background-color: $pink;
                }
            }
        }
    }
}

.reso_socio{
    display: inline-block;
    li{
        float: left;
        margin-bottom: 22px;
        a{
            height: 34px;
            background-color: #ffffff;
            display: inline-block;
            margin-right: 35px;
            border-radius: 17px;
            line-height: 34px;
            padding: 0 14px;
            &.round{
                padding: 0;
                margin-right: 23px;
                width: 33px;
                text-align: center;
                line-height: 33px;
                i{
                    font-size: 18px;
                    color: $pink;
                }
            }
            &.line{
                position: relative;
                padding-left: 45px;
                i{
                    position: absolute;
                    left: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover{
                    background-color: black;
                    text-decoration: none;
                }
            }
            &:hover{
                background-color: $pink;
                i{
                    color: white;
                }
                span{
                    color: white;
                }
            }
        }
    }
    span{
        color: #000000;
        font-size: 18px;
        font-weight: 700;
    }
}

#footer_bottom{
    padding: 20px 0;
    text-align: center;
    p{
        margin: 0;
        color: rgba(64, 85, 116, 0.56);
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        a{
            color: inherit;
        }
    }
}

#header_page{
    h1{
        color: #000000;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.1em;
        text-align: center;
        margin: 20px 0 44px;
    }
}

.page{
    position: relative;
    padding-top: 52px;
    &:before{
        position: absolute;
        content: " ";
        height: 160px;
        background-color: #e8ebee;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
    }
    &.small{
        padding-top: 20px;
        &:before{
            height: 15px;
        }
        #header_page{
            h1{
                margin-bottom: 33px;
            }
        }
    }
}

.ariane{
    background-color: #ffffff;
    padding: 0 14px;
    ul{
        display: inline-block;
        li{
            float: left;
            a{
                color: #303030;
                font-size: 14px;
                font-weight: 400;
                display: inline-block;
                line-height: 44px;
                i.fa-home{
                    color: $pink;
                    font-size: 16px;
                }
            }
            i.fa-angle-right{
                font-size: 21px;
                margin: 0 13px;
                transform: translateY(4px);
            }
        }
    }
}

.slider_responsive, .slider_responsive2 {
    img{
        max-width: 100%;
        height: auto;
    }
}

.toggle_me{
    margin: 50px 0;
    h3{
        border: 1px solid #c3c9cb;
        background-color: rgba(0, 0, 0, 0);
        margin: 0;
        padding: 13px;
        position: relative;
        margin-bottom: 16px;
        cursor: pointer;
        i{
            position: absolute;
            right: 14px;
            top: 11px;
            display: inline-block;
            width: 30px;
            height: 31px;
            background-color: #e8ebee;
            border-radius: 50%;
            color: #000000;
            font-size: 19px;
            text-align: center;
            line-height: 34px;
            &.fa-angle-up{
                display: none;
            }
        }
    }
    .texte{
        display: none;
        padding: 0 21px 50px 21px;
    }
    .toggle:hover{
        h3{
            background-color: $pink;
            color: white;
        }
    }
    .toggle.active{
        h3{
            background-color: $pink;
            color: white;
        }
        i.fa-angle-up{
            display: inline-block;
            background-color: white;
        }
        i.fa-angle-down{
            display: none;
        }
        .texte{
            display: block;
        }
    }
}

select::-ms-expand {
    display: none;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    cursor: pointer;
}
.select_container{
    padding-bottom: 48px;
    position: relative;
    i{
        position: absolute;
        right: 14px;
        top: 14px;
        color: $pink;
        font-size: 24px;
    }
    .dd{
        .ddTitle{
            height: 48px;
            border-radius: 24px;
            border: 2px solid #e7eced;
            background-color: #ffffff;
            line-height: 48px;
            cursor: pointer;
            padding: 0 26px;
            span.arrow{
                background-position: 0 center !important;
            }
            img{
                width: 24px;
                height: auto;
                max-width: 24px;
                max-height: 21px;
                margin-top: 12px;
                float: left;
                display: inline-block;
            }
            .ddTitleText{
                line-height: 48px;
                display: inline-block;

                color: $pink;
                font-size: 14px;
                font-weight: 700;
                padding-left: 13px;
                letter-spacing: 0.56px;
                text-transform: uppercase;
            }

        }
        .ddChild{
            a {
                padding: 0 26px;
                img{
                    width: auto;
                    height: 21px;
                }
                span{
                    padding-left: 13px;
                    line-height: 37px;
                    color: $pink;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 0.56px;
                    text-transform: uppercase;
                }
                &.selected,&:hover{
                    background-color: #e8ebee;
                }
            }
        }
    }
}

.date{
    background: transparent url(../images/bg_date.svg) no-repeat 0 0;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    width: 71px;
    height: 71px;
    font-size: 13px;
    padding-top: 15px;
    strong{
        display: inline-block;
        width: 100%;
        font-size: 29px;
    }
}

.marqueur{
    height: 33px;
    line-height: 33px;
    background-color: #ffffff;
    color: #c31f7c;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 10px;
    i{
        margin: 0 7px 0 0;
        position: relative;
        transform: translateY(-9px);
    }
}

.main_article{
    margin-bottom: 45px;
    .image{
        position: relative;
        margin-bottom: 43px;
        .date{
            position: absolute;
            bottom: -19px;
            left: 14px;
        }
        .marqueur{
            position: absolute;
            left: 112px;
            bottom: 0;
        }
    }
    .texte{
        
        a{
            color: #303030;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            display: inline-block;
            margin-bottom: 14px;
        }
        h6{
            color: #303030;
            font-size: 16px;
            font-weight: 400;
            line-height: 31px;
            text-transform: uppercase;
            border-bottom: 1px solid #303030;
            margin-bottom: 13px;
        }
        p{
            color: #303030;
            line-height: 30px;
            strong{
                font-weight: 700;
                font-size: 20px;
            }
        }
    }
}

.regular_article{
    margin-bottom: 32px;
    display: inline-block;
    width: 100%;
    position: relative;
    .image{
        width: calc(100% - 35px);
        position: relative;
        margin-bottom: 23px;
        overflow: hidden;
        height: 169px;
        img{
            min-height: 100%;
            min-width: 100%;
            height: auto;
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .date{
        position: absolute;
        right: 0;
        top: 46px;
    }
    a{
        color: #303030;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        display: inline-block;
        margin-bottom: 14px;
    }
    p{
        color: #303030;
        font-size: 16px;
        font-weight: 400;
        line-height: 31px;
    }
    .marqueur{
        background-color: transparent;
        font-size: 12px;
        i{
            width: 8px;
            height: 12px;
            transform: translate(0);
        }
    }
}

.sidebar{
    .titre{
        display: inline-block;
        color: $pink;
        font-size: 23px;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 1px solid #000000;
        padding-bottom: 4px;
        line-height: 29px;
        margin-bottom: 16px;
        width: 100%;
    }
}

.cats{
    margin-bottom: 63px;
    li:not(:last-child){
        border-bottom: 2px solid #e8ebee;
    }
    li{
        padding: 10px 0;
    }
    a{
        display: inline-block;
        color: #303030;
        font-size: 17px;
        line-height: 25px;
        font-weight: 700;
        span{
            font-weight: 400;
        }
        &:hover{
            color: $pink;
            text-decoration: none;
        }
    }
}

.articles{
    margin-bottom: 63px;
    li:not(:last-child){
        border-bottom: 2px solid #e8ebee;
    }
    li{
        padding: 20px 0;
        display: inline-block;
        width: 100%;
    }
    a{
        color: #303030;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        &:hover{
            color: $pink;
            text-decoration: none;
        }
    }
    .image{
        position: relative;
        overflow: hidden;
        width: 116px;
        height: 72px;
        float: left;
        margin: 0 18px 0 0;
        img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
        }
    }
}

.newsletter_sidebar{
    border-top: 4px solid black;
    padding: 14px 0 0 0;
    margin-bottom: 63px;
    h6{
        color: #000000;
        font-size: 20px;
        font-weight: 700;
    }
    .input_text{
        width: 100%;
        height: 40px;
        border-radius: 26px;
        border: 2px solid rgba(99, 111, 121, 0.25);
        background-color: #ffffff;
        color: black;
        font-size: 15px;
        padding: 0 15px;
        margin: 19px 0 12px;
    }
    label{
        color: #000000;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
        position: relative;
        padding-left: 37px;
        width: 100%;
        .icheckbox{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }
    button{
        float: right;
        color: $pink;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1.26px;
        text-transform: uppercase;
        border: 0;
        padding: 0;
        background-color: transparent;
        i{
            font-size: 19px;
            margin-left: 10px;
        }
    }
}


#les_produits{
    .chapo{
        color: #303030;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 70px;
    }
}

.produit_style{
    margin-bottom: 40px;
    padding: 12px 0 0 0;
    .image{
        background: lightgray;
        position: relative;
        overflow: hidden;
        height: 385px;
        > img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
        }
        &:hover{
            a.titre{
                top: 50px;
            }
            a.lien{
                opacity: 1;
            }
        }
    }
    a.titre{
        color: #ffffff;
        font-size: 41px;
        font-weight: 700;
        line-height: 34px;
        position: absolute;
        display: inline-block;
        background: transparent url(../images/trace.svg) no-repeat 0 0;
        left: 25px;
        bottom: 23px;
        height: 130px;
        padding: 40px 0 0 43px;
        z-index: 2;
        &:hover{
            text-decoration: none;
        }
    }
    a.lien{
        opacity: 0;
        border: 1px solid #707070;
        background-color: rgba($pink,0.8);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.56px;
        text-transform: uppercase;
        text-align: center;
        padding: 270px 0 0 0;
        img{
            margin-bottom: 5px;
        }
        &:hover{
            text-decoration: none;
        }
        .details {
            background: url(../images/sep_commande.svg) no-repeat top center;
            padding-top: 15px;
        }
    }
    p{
        margin-top: 4px;
        border-top: 2px solid $pink;
        color: #303030;
        font-size: 16px;
        font-weight: 400;
        line-height: 31px;
        padding: 18px 0 0 0;
    }
}

#les_facades{
    padding: 27px 0 0 0;
}

#meme_categorie{
    background-color: #e8ebee;
    margin: 197px 0 0 0;
    span.titre{
        color: #000000;
        font-size: 25px;
        font-weight: 700;
        line-height: 29px;
        display: inline-block;
        border-bottom: 1px solid black;
        transform: translateY(-90px);
        max-width: 75%;
        padding-bottom: 31px;
    }
    .first{
        height: 281px;
        background-color: $pink;
        margin-top: -61px;
        margin-bottom: 29px;
        a{
            color: #ffffff;
            font-size: 25px;
            font-weight: 400;
            display: inline-block;
            line-height: 29px;
            position: absolute;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
            &:hover{
                text-decoration: none;
            }
            span{
                display: inline-block;
                width: 100%;
                font-size: 34px;
            }
        }
    }
    .image{
        height: 281px;
        position: relative;
        overflow: hidden;
        img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
        }
    }
    .meme_cat{
        margin-bottom: 10px;
        a{
            display: inline-block;
            text-align: center;
            color: $pink;
            font-size: 23px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 24px 0;
            width: 100%;
        }
    }
}

.selection_cat{
    text-align: center;
    ul{
        display: inline-block;
        li{
            float: left;
            margin: 0 30px 30px 30px;
            a{
                display: inline-block;                
                border-radius: 21px;
                border: 1px solid #646464;
                background-color: #ffffff;
                color: $pink;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.56px;
                text-transform: uppercase;
                padding: 8px 14px;
                i{
                    font-size: 19px;
                    margin-right: 8px;
                }
                &.current, &:hover{
                    background-color: $pink;
                    border-color: $pink;
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
    select{
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: 2px solid #e7eced;
        background-color: #ffffff;
        color: #000000;
        font-size: 15px;
        padding: 0 29px;
        &:focus{
            border: 0;
            outline: none;
        }
    }
}

#responsiveTabsDemo{
    margin-bottom: 50px;
}

.r-tabs-panel{
    border-top: 2px solid #e8ebee;
}

.r-tabs-tab, .r-tabs-accordion-title{
    margin-bottom: 20px;
    &.r-tabs-state-active{
        margin-bottom: 0;
        a{
            color: white;
            background-color: $pink;
        }
    }
    a{
        display: inline-block;
        background-color: #e8ebee;
        padding: 5px 16px;
        color: #000000;
        font-size: 17px;
        font-weight: 700;
        line-height: 37px;
        text-transform: uppercase;
        margin-right: 14px;
    }
}


#ouvrir_compte{
    background-color: #e8ebee;
    padding: 20px 20px;
    margin-bottom: 50px;
    h3{
        color: #000000;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        margin-bottom: 16px;
    }
    p{
        color: #303030;
        font-size: 16px;
        font-weight: 400;
        line-height: 31px;
        margin-bottom: 31px;
    }
    #capture{
        margin: 42px 0 10px 0;
    }
}

#avantages{
    h4{
        text-align: center;
        color: #000000;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2em;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    span{
        display: inline-block;
        width: 80%;
        color: $pink;
        font-size: 32px;
        font-weight: 700;
        line-height: 62px;
        border-bottom: 1px solid #e8ebee;
        margin-bottom: 15px;
    }
    p{
        color: #303030;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 10px;
    }
}

#cle_reussite{
    margin: 60px -15px 0;
    background: transparent url(../images/Photo_public.jpg) no-repeat center top;
    background-size: auto 196px;
    padding: 147px 0 0 15px;
    .acc{
        background: transparent url(../images/trace.svg) no-repeat;
        padding: 61px 40px 0 32px;
        strong{
            color: $pink;
            font-size: 30px;
            font-weight: 700;
            display: inline-block;
            line-height: 1.2em;
            margin-bottom: 10px;
        }
        span{
            color: #000000;
            font-size: 26px;
            font-weight: 400;
            line-height: 1em;
            text-transform: uppercase;
            display: inline-block;
        }
    }
}

#temoignages{
    h5{
        border-top: 1px solid black;
        color: $pink;
        font-size: 23px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 15px 0;
        margin-bottom: 0;
    }
    .texte{
        padding: 100px 30px 30px 30px;
        background-image: linear-gradient(180deg, #e8ebee 0%, #ffffff 100%);
        position: relative;
        margin-top: 115px;
        margin-bottom: 20px;
        img{
            position: absolute;
            top: -90px;
            left: 50%;
            transform: translateX(-50%);
        }
        span{
            color: $pink;
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            display: inline-block;
            width: 100%;
            font-style: italic;
        }
    }
}

#creation_compte_pro {
    margin-top: 50px;
}

//Timeline
.time {
    overflow-x: hidden;
    position: relative;
    padding: 100px 0;

    &::before {
        content: '';
        background: $pink2;
        width: 5px;
        height: 95%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
.item-timeline {
    width: 100%;
    margin-bottom: 70px;

    &:nth-child(even) {
        .content-timeline {
            float: right;
            padding: 40px 30px 10px 30px;

            .date-timeline {
                right: auto;
                left: 0;
            }

            &::after {
                content: '';
                position: absolute;
                border-style: solid;
                width: 0;
                height: 0;
                top: 30px;
                left: -15px;
                border-width: 10px 15px 10px 0;
                border-color: transparent #f5f5f5 transparent transparent;
            }
        }
    }
    &::after {
        content: '';
        display: block;
        clear: both;
    }
    .img-timeline-header2020 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("https://pim.kazed.store/b2bweb/kazed/histoire/2020_ban.jpg") center center no-repeat;
        background-size: cover;
    }
    .img-timeline-header2019 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("https://pim.kazed.store/b2bweb/kazed/histoire/2019_ban.jpg") center center no-repeat;
        background-size: cover;
    }
    .img-timeline-header2018 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("https://pim.kazed.store/b2bweb/kazed/histoire/2018_ban.jpg") center center no-repeat;
        background-size: cover;
    }
    .img-timeline-header2017 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("https://pim.kazed.store/b2bweb/kazed/histoire/2017_ban.jpg") center center no-repeat;
        background-size: cover;
    }
    .img-timeline-header2016 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('https://pim.kazed.store/b2bweb/kazed/histoire/2016_ban.jpg') center center no-repeat;
        background-size: cover;
    }
    .img-timeline-header2008 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("https://pim.kazed.store/b2bweb/kazed/histoire/2008_ban.jpg") center center no-repeat;
        background-size: cover;
    }
    .img-timeline-header1980 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("https://pim.kazed.store/b2bweb/kazed/histoire/1980_ban.jpg") center center no-repeat;
        background-size: cover;
    }
    .img-timeline-header1965 {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("https://pim.kazed.store/b2bweb/kazed/histoire/1965_ban.jpg") center center no-repeat;
        background-size: cover;
    }
}
.content-timeline {
    position: relative;
    width: 45%;
    padding: 40px 30px;
    border-radius: 4px;
    background: #f5f5f5;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
    &::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        right: -15px;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #f5f5f5;
    }
}
.img-timeline {
    width: 30px;
    height: 30px;
    background: $pink;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px;
}
.a-timeline {
    background: $pink;
    color: #fff;
    padding: 8px 20px;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 2px;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.6);
    &:hover {
        background: $pink;
        color: $white;
        text-decoration: none;
    }
    &:active {
        background: $pink;
        color: #fff;
        text-decoration: none;
    }
    &:focus {
        background: $pink;
        color: #fff;
        text-decoration: none;
    }
}
.card-timeline {
    padding: 0 !important;
    .p-timeline {
        padding: 0 20px;
    }
    .a-timeline {
        margin-left: 20px;
    }
}
.img-timeline-header1965, .img-timeline-header1980, .img-timeline-header2008, .img-timeline-header2016, .img-timeline-header2017, .img-timeline-header2018, .img-timeline-header2019, .img-timeline-header2020 {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
    .h2-timeline {
        position: absolute;
        bottom: 5px;
        left: 20px;
    }
}
blockquote {
    margin-top: 30px;
    color: #757575;
    border-left-color: $pink;
    padding: 0 20px;
}
.date-timeline {
    background: $pink;
    display: inline-block;
    color: #fff;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

//Page Type
.contenu {
    .alignRight {
        float: right;
        //margin: 20px 20px 20px 0;
        margin: 0 0 50px 65px;
    }
    .paraFond {
        margin: 60px 0 60px 0;
        padding: 40px;
        background-color: #e8ebee;
    }
    .img-center {
        display: flex;
        justify-content: center;
        margin: 0 0 0 0;
        .alignLeft {
            margin: 0 0 60px 0;
        }
    }
    .img_duo {
        margin-right: 50px;
    }
}

.bouton2{
    display: inline-block;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.68px;
    text-transform: uppercase;
    border: 1px solid black;
    padding: 10px 20px 11px;
    position: relative;
    background-color: $pink;
    &:after{
        content: " ";
        height: 12px;
        border: 4px solid white;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
        width: 157px;
        background-color: #cfd4dc;
    }
    &:hover{
        text-decoration: none;
        background-color: #fff;
        color: $pink;
        border-color: #fff;
    }
    &:after:hover{
        border: 0;
        bottom: -3px;
        height: 4px;
        width: 149px;
    }
}

//modeles
.model{
    padding: 10px;
    margin-bottom: 20px;
    &:hover{
        box-shadow: 2px 2px 5px 5px #f1f2f3;
    }
}
.img_model{
    width: 100%;
    cursor: pointer;
}

//nuancier
.img_nuancier{
    width: 100%;
    cursor: pointer;
    transition: transform .2s;
    border-radius: 5px;
    &:hover{
        transform: scale(1.1);
    }
}
.nuancier_cadre{
    p{
        text-align: center;
        font-size: 12px;
        line-height: normal;
        margin-top: 10px;
    }
}
ul a{
    color: $pink;
    &:hover{
        color: $pink2;
    }
}

//valeurs
.valeur{
    margin-bottom: 20px;
    .card_val{
        height: 100%;
        border: solid 1px #f1f2f3;
        border-radius: 5px;
        box-shadow: 2px 2px 2px 2px #f1f2f3;
    }
}

//façade collection
.fac_collec{
    margin-bottom: 10px;
    img{
        width: 100%;
        padding-top: 15px;
    }
    &:hover{
        box-shadow: 2px 2px 5px 5px #f1f2f3;
    }
    p{
        text-align: center;
        font-size: 12px;
        line-height: normal;
        margin-top: 10px;
    }
}

//tete de lit
.tete{
    .img_tete{
        width: 100%;
        border-radius: 5px;
    }
}
.tete_lit{
    height: 100%;
}

//conseils
.texte{
   padding: 0 !important;
}
.img_conseil{
    width: 100%;
    margin-bottom: 10px;
}
ol {
    li {
        color: $pink;
        font-weight: bold;

        a {
            color: $pink;
            font-weight: normal;

            &:hover {
                color: $pink;
                text-decoration: underline;
            }
        }
    }
}

//newsletter
.news{
    background-color: rgba(231, 236, 237, 0.7);
    p{
        font-size: 14px;
    }
    .formulaire{
        background-color: transparent;
    }
}

@import "public/_fromTablet";
@import "public/_fromPc";
@import "public/_fromPcXl";
@import "public/_mobileOnly";
@import "public/_tabletOnly";
@import "public/_pcOnly";