@font-face {
    font-family: 'Century Gothic WGL';
    src: url('CentGothWGL.woff2') format('woff2'),
        url('CentGothWGL.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic WGL';
    src: url('CentGothWGL-Italic.woff2') format('woff2'),
        url('CentGothWGL-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic WGL';
    src: url('CentGothWGL-Bold.woff2') format('woff2'),
        url('CentGothWGL-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic WGL';
    src: url('CentGothWGL-BdIt.woff2') format('woff2'),
        url('CentGothWGL-BdIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}