$break-xs : 576px;
$break-md : 768px;
$break-lg : 992px;
$break-xl : 1380px;

$grid-breakpoints: (
  xxs: 0,
  xs: 576px,
  md: 768px,
  lg: 992px,
  xl: 1380px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1320px
);

$pink: #c31f7c;
$pink-hover: #90005c;
$pink2 : rgba(195, 31, 124, 0.5);

$pagination-active-bg: $pink;
$pagination-hover-bg: $pink-hover;

@import 'fonts/fonts.css';
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import '~bootstrap-select/sass/bootstrap-select.scss';
@import 'slick-carousel/slick/slick.scss';
@import 'fontawesome.scss';
@import 'select2.css';
@import 'algolia-autocomplete.scss';
@import 'icheck.scss';
@import '~lightgallery/dist/css/lightgallery.css';

a {
    transition: all ease 0.3s;
    display: inline-block;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

ul {
    margin: 0;
    padding: 0;
    li{
        margin: 0;
        padding: 0;
        list-style: none outside none;
    }
}

body {
    font-family: 'Century Gothic WGL';
    line-height: 1.2em;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.input_file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.p-coche {
    font-size: 14px !important;
    line-height: normal !important;
}
.input_file + label {
  display: inline-block;
  color: $pink;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.68px;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 7px 66px 6px 12px;
  position: relative;
  background-color: white;
  cursor: pointer;

  i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.input_file:focus + label,
.input_file + label:hover {

}

.pointer {
    cursor: pointer;
}

.pagination {
    text-align: center;
    margin-bottom: 30px;
    ul {
        display: inline-block;
        li {
            float: left;
            padding: 0 15px;
            &:not(:first-child):not(:last-child):before {
                content: " ";
                position: absolute;
                margin-left: -20px;
                top: 12px;
                width: 9px;
                height: 9px;
                border-radius: 5px;
                background-color: #c31f7c;
            }
            &:last-child {
                padding: 0;
            }
            a {
                color: #303030;
                font-size: 21px;
                font-weight: 400;
                line-height: 34px;
                &.current{
                    color: $pink;
                    font-weight: bold;
                }
            }
            .far{
                color: $pink;
                font-size: 27px;
                transform: translateY(4px);
            }
        }
    }
}

.iradio{
    position: relative;
    display: inline-block;
    width: 15px;
    margin-left: 10px;
    margin-right: 5px;
    height: 15px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid rgba(99, 111, 121, 0.38);
    background-color: #ffffff;
    &.checked{
        z-index: 1;
        background: transparent;
        &:before{
            content: '';
            z-index: -1;
            position: absolute;
            width: 13px;
            height: 13px;
            top: 0px;
            border: 1px solid #fff;
            left: 0px;
            border-radius: 50%;
            background-color: $pink;
        }
    }
}
.icheckbox{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid rgba(99, 111, 121, 0.38);
    background-color: #ffffff;
    cursor: pointer;
    margin-right: 20px;
    &.checked{
        z-index: 1;
        background: transparent;
        &:before{
            content: '';
            z-index: -1;
            position: absolute;
            width: 16px;
            height: 16px;
            top: 1px;
            border: 1px solid #fff;
            left: 1px;border-radius: 2px;
            background-color: $pink;
        }
    }
}

.background-grey {
    background-color: #f1f2f3;
    min-height: 800px;
    padding-bottom: 30px;
}

.bd-pink {
    border-color: $pink;
}

.bg-pink {
    background-color: $pink;
    border-color: $pink;
    color: white;
}

.text-pink {
    color: $pink;
}

.link-pink {
    color: $pink;
    &:hover {
        color: $pink-hover;
        text-decoration: none;
    }
}

.js-loading-overlay {
  
  position:absolute;
  top:0; bottom:0;
  left:0; right:0;
  
  z-index:199;
  
  background:#fff url("../images/loader.gif") no-repeat center;
  opacity:0.5;
  
  border-radius: inherit;
}

.nowrap {
    white-space : nowrap;
}

.col-md-35 {
    flex: 0 0 30%;
    max-width: 30%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-md-85 {
    flex: 0 0 70%;
    max-width: 70%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

#main_menu{
    text-align: right;
    #icon_fermer{
        font-size: 41px;
        color: black;
        &:hover{
            text-decoration: none;
        }
        span{
            font-size: 14px;
            position: absolute;
            white-space: nowrap;
            right: 50px;
            top: 21px;
        }
    }

    .menu_burger_lg{
        font-size: 31px;
        color: black;
        width: 100%;
        text-align: center;
        span{
            display: none;
            color: #000000;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 30px;
            padding-right: 26px;
            transform: translateY(-6px);
        }
        .fa-bars{
            margin-bottom: 30px;
        }
        .fa-times{
            display: none;
        }
    }
    &.open{
        min-width: 268px;
        .menu_burger_lg {
            text-align: right;
            padding: 30px;
            span{
                display: inline-block;
            }
            .fa-bars{
            display: none;
            }
            .fa-times{
                display: inline-block;
            }
        }
        ul{
            margin: 0 30px 60px 0;
            li a span{
                display: inline-block;
            }
        }
    }
    &.fermer{
        left: 0;
        width: 81px !important;
    }
    h3 {
        color: $pink;
        a {
            color: $pink;
        }
        font-size: 17px;
        cursor: pointer;
        font-weight: 700;
        letter-spacing: 0.9px;
        line-height: 1.2em;
        text-transform: uppercase;
        border-bottom: 1px solid #000000;
        padding: 10px 30px 10px 0;
        position: relative;
        i{
            font-size: 21px;
            position: relative;
            left: 10px;
            top: 3px;
            &.fa-angle-up{
                display: none;
            }
        }
        &.active {
            i {
                display: none;
                &.fa-angle-up {
                    display: inline-block;
                }
            }
        }
    }
    .first {
        margin-top: 20px;
    }
    h4{
        color: $pink;
        font-size: 16px;
        cursor: pointer;
        font-weight: 700;
        letter-spacing: 0.9px;
        line-height: 1.2em;
        text-transform: uppercase;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
        padding: 10px 30px 10px 0;
        position: relative;
        a {
            color: $pink;
        }
        i{
            color: black;
            font-size: 18px;
            position: absolute;
            right: 0;
            top: 13px;
            &.fa-angle-up{
                display: none;
            }
        }
        &.active{
            i{
                display: none;
                &.fa-angle-up{
                    display: inline-block;
                }
            }
        }
    }
    ul {
        display: none;
        margin: 13px 0 27px;
        li {
            a {
                color: #000000;
                font-size: 16px;
                font-weight: 400;
                line-height: 31px;
                display: inline-block;
                padding-right: 30px;
            }
            &.toggle_lien {
                > a > i {
                    color: black;    
                    position: relative;
                    top: 2px;
                    left: 1px;
                    &.fa-angle-up {
                        display: none;
                    }
                }
                &.active {
                    > a > i {
                        display: none;
                        &.fa-angle-up {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

.contenu{
    h1{
        color: #000000;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.1em;
    }
    .chapo{
        color: #303030;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
    }
    h2{
        color: $pink;
        font-size: 29px;
        font-weight: 400;
        line-height: 1.1em;
        margin: 51px 0 31px;
    }
    h3{
        color: $pink;
        font-size: 23px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.1em;
        margin-bottom: 43px;
    }
    h4{
        color: #000000;
        font-size: 19px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1.1em;
        margin-bottom: 34px;
    }
    h5{
        color: $pink;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.1em;
        margin-bottom: 27px;
    }
    h6{
        color: $pink;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.1em;
        margin-bottom: 20px;
    }
    .alignLeft{
        float: left;
        margin: 20px 20px 20px 0;
    }
    p{
        color: #303030;
        font-size: 16px;
        font-weight: 400;
        line-height: 31px;
        a{
            color: $pink;
            text-decoration: underline;
        }
    }
    ul{
        display: flow-root;
        li:not([data-target]) {
            padding-left: 30px;
            position: relative;
            margin-bottom: 5px;
            line-height: 31px;
            &:before{
                content: " ";
                position: absolute;
                left: 0;
                top: 11px;
                width: 9px;
                height: 9px;
                border-radius: 5px;
                background-color: $pink;
            }
        }
    }
    table{
        thead{
            color: #ffffff;
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
            text-transform: uppercase;
            th{
                padding: 10px 19px;
                background-color: $pink;
            }
        }
        tbody{
            color: #303030;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            tr:nth-child(even){
                background-color: #e8ebee;
            }
            td{
                padding: 10px 19px;
            }
        }
    }
    figure{
        figcaption{
            padding: 10px 16px;
            color: #000000;
            font-size: 15px;
            letter-spacing: 0.15px;
            line-height: 20px;
            background-color: #e8ebee;
        }
    }
    .slider_base{
        .slick-slide{
            position: relative;
            height: 144px;
            overflow: hidden;
            img{
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%,-50%);
                min-width: 100%;
                height: auto;
            }
        }
    }
    .slider_base_dots{
        margin-top: 68px;
        .slick-slide{
            img{
                margin: 0 auto;
            }
        }
    }
    .slider_responsive{
        margin-left: -10px;
        margin-right: -10px;
        .slick-slide{
            height: 147px;
            position: relative;
            overflow: hidden;;
            margin: 0 10px;
            img{
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%,-50%);
                min-width: 100%;
                height: auto;
            }
        }
    }
}

.produit_style_2{
    margin-bottom: 40px;
    padding: 12px 0 0 0;
    .image{
        position: relative;
        overflow: hidden;
        height: 315px;
        margin-bottom: 41px;
        > img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
        }
        &:hover{
            a{
                opacity: 1;
            }
        }
        a{
            opacity: 0;
            width: 257px;
            height: 241px;
            border: 1px solid #707070;
            background-color: rgba($pink,0.8);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.56px;
            text-transform: uppercase;
            text-align: center;
            padding: 109px 0 0 0;
            img{
                margin-bottom: 5px;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
    p{
        color: #303030;
        font-size: 16px;
        font-weight: 400;
        line-height: 31px;
        padding: 12px 0 0 0;
    }
    h2{
        color: $pink;
        font-size: 29px;
        font-weight: 400;
        border-bottom: 1px solid #646464;
        padding-bottom: 19px;
        a{
            color: inherit;
            &:hover{
                text-decoration: none;
            }
        }
    }
}


.petit_bouton{
    margin-top: 5px;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 5px;
    width: 100%;
    &:hover{
        color: #000000;
        text-decoration: underline;
        font-weight: bold;
    }
}

//Téléchargement pour le connecté et le non connecté
.doc {
    padding: 10px;
    margin-bottom: 20px;
    transition: transform .2s;
    &:hover {
        transform: scale(1.1);
        box-shadow: 2px 2px 5px 5px #f1f2f3;
    }
    .tele_lien {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
        .tele_h3 {
            text-transform: full-size-kana !important;
            color: $pink;
            font-size: 23px;
            margin-bottom: 30px !important;
        }
        .tele_h4 {
            margin-bottom: 5px !important;
            text-transform: full-size-kana !important;
            font-size: 19px;
        }
        p{
            line-height: normal;
            color: black;
        }
        .tele_h4{
            color: black;
        }
        .tele_p {
            margin-bottom: 0 !important;
            font-size: 12px !important;
            &:nth-of-type(1) {
                margin-top: 20px !important;
            }
        }
    }
    .miniature {
        margin: 0 20px 0 20px;
        img {
            width: 100%;
            border-radius: 30px 30px 0 30px;
            vertical-align: middle;
        }
    }
}

//pointer images gallerie
.cursor_img{
    cursor: pointer;
}
.slider_kosmic .slick-prev, 
.slider_kosmic .slick-next {
    position: absolute;
    z-index: 100;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border-radius: 50%;
    outline: none;
    background: white;
}
.slider_kosmic .slick-next {
    right: -25px;
}
.slider_kosmic .slick-prev {
    left: -25px;
}
.slider_kosmic .item {
    margin-right: 15px;
}
.slider_kosmic i {
    color: $pink;
}

//Contact
.formulaire{
    background-color: rgba(#e7eced, 0.7);
    width: auto;
    padding: 46px;
    .row{
        padding-bottom: 30px;
    }
    &.connected {
        background-color: #f1f2f3;
    }
    .input_text, select, textarea{
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: 2px solid #e7eced;
        background-color: #ffffff;
        color: #000000;
        font-size: 15px;
        padding: 0 29px;
        &:focus{
            border: 0;
            outline: none;
        }
        margin-bottom: 10px;
    }
    .custom-select {
        height: 35px;
        border-radius: 0px;
    }
    textarea{
        height: 169px;
        resize: none;
        line-height: 1.2em;
        padding: 10px 29px;
        border-radius: 5px;
    }
    label:not(.bouton2) {
        color: #303030;
        font-weight: 700;
        margin: 0;
        font-size: 14px;
        line-height: normal;
    }
    .bouton2 {
        display: inline-block;
        color: #fff;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 0.68px;
        text-transform: uppercase;
        border: 1px solid black;
        padding: 10px 20px 11px;
        position: relative;
        background-color: #c31f7c;
    }
    .p_form{
        font-size: 12px;
        line-height: normal;
    }
    .input_text {
        height: 35px;
    }
}