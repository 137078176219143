@media (min-width: $break-xl){
    #logo{
        left: 99px;
    }
    .produit_style{
        .image{
            height: 385px;
        }
    }
    #ouvrir_compte{
        #fleches{
            position: absolute;
            top: 185px;
            left: 614px;
        }
    }
}