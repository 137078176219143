@media (min-width: $break-lg){

    #header{
        .icon_user_container{
            padding-left: 35px;
            right: 167px;
            line-height: 1em;
            top: 18px;
            .icon_user{
                position: absolute;
                left: 0;
                top: 3px;
            }
        }        
    }

    #actions{
        padding: 33px 0;
        .configurateur{
            h4{
                font-size: 29px;
                margin: -20px 0 19px 35px;
            }
            p{
                margin-left: 35px;
            }
        }
        .bloc{
            margin: 0;
        }
    }

    .produit{
        .image{
            height: 385px;
        }
        h2{
            margin: 18px 0 13px;
        }
        p{
            margin-bottom: 32px;
        }
    }


    #sav_news{
        margin-top: 112px;
        padding: 40px 0;
        #illu_sav{
            margin: -93px 0 0 0;
        }
    }

    .newsletter{
        padding: 5px 0;
    }

    #reassurance{
        padding: 48px 0;
        .sep{
            position: relative;
            &:before{
                position: absolute;
                content: " ";
                left: 0;
                top: 3px;
                background: transparent url(../../images/sep_reassurance.svg) no-repeat 0 0;
                width: 20px;
                height: 123px;
            }
        }
    }

    #slider_accueil{
        .slide{
            height: 560px;
            padding-top: 85px;
        }
        h1{
            font-size: 73px;
            margin: 40px 0 101px;
        }
        span{
            font-size: 20px;
        }
        a{
            font-size: 17px;
            padding-right: 30px;
            i{
                font-size: 39px;
            }
        }
        .container{
            padding-top: 36px;
            padding-bottom: 25px;
            &:before{
                width: 230px;
            }
        }
        .texte{
            padding-left: 33px;
        }
    }

    #footer_top{
        padding: 31px 0 15px 0;
        .coordonnees{
            padding-bottom: 0;
        }
        #logo_footer{
            margin: 18px 0 0 0;
            display: inline-block;
        }
    }

    .reso_socio{
        margin: 17px 0 0 0;
    }

    .contenu{
        .alignLeft{
            margin: 0 65px 50px 0;
        }
        .slider_responsive{
            .slick-slide{
                height: 248px;
            }
        }
        .slider_base{
            .slick-slide{
                height: 458px;
                margin: 0 110px;
            }
        }
        .slider_base_dots{
            margin-top: 55px;
        }
    }

    .slider_base + .navSlider-dessous{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        padding: 0;
        .slick-arrow{
            &.left{
                margin-left: 50px;
            }
            &.right{
                margin-right: 50px;
            }
        }
    }

    #header_page{
        h1{
            font-size: 41px;
            margin: -50px 0 39px 0;
            position: relative;
            &:before{
                position: absolute;
                content: " ";
                top: 65px;
                right: 0;
                left: 0;
                height: 4px;
                background-color: #ebebeb;
            }
            span{
                background-color: white;
                display: inline-block;
                padding: 31px 61px;
                position: relative;
            }
        }
    }

    .formulaire{
        margin: 0;
    }

    .page{
        &.small{
            padding-top: 49px;
            &:before{
                height: 97px;
            }
            #header_page{
                h1{
                    margin: 38px 0 43px 0;
                    &:before{
                        top: 55px;
                    }
                }
            }
        }
    }

    .main_article{
        margin-bottom: 87px;
        .image{
            .marqueur{
                bottom: -19px;
            }
        }
    }

    .regular_article{
        .date{
            right: auto;
            left: 235px;
        }
        .image{
            width: 271px;
            float: left;
            margin: 0;
        }
        .texte{
            float: right;
            width: calc(100% - 356px); /* 271 + 85px de gutter */
        }
        &.grey{
            background-color: #e8ebee;
        }
        a{
            margin: 16px 0 10px 0;
        }
        p{
            margin-bottom: 8px;
        }
    }

    .produit_style{
        margin-bottom: 95px;
    }

    .produit_style_2{
        .image{
            height: 386px;
        }
    }
    #meme_categorie{
        margin: 201px 0 0 0;
        padding-bottom: 45px;
        span.titre{
            padding: 0 120px 26px 120px;
            transform: translateY(-50px);
            margin-bottom: 32px;
        }
        .meme_cat{
            margin: 0;
        }
    }
    .r-tabs .r-tabs-panel{
        padding: 53px 56px !important;
    }
    #main_menu{
        text-align: right;
    }
    .produit_style{
        .image{
            height: 289px;
        }
    }
    .kazed_pro{
        .contenu.row{
            h2{
                margin-top: 13px;
                line-height: 1.25em;
            }
        } 
    }
    #ouvrir_compte{
        padding: 42px 54px 53px 54px;
        margin-top: 62px;
        position: relative;
        margin-bottom: 140px;
        h3{
            font-size: 31px;
            line-height: 38px;
            margin-bottom: 42px;
        }
        p{
            width: 40%;
        }
        #capture{
            position: absolute;
            right: -61px;
            top: 11px;
            margin: 0;
        }
    }
    #avantages{
        h4{
            font-size: 23px;
            margin-bottom: 30px;
        }
        .bloc{
            margin-bottom: 20px;
        }
        #liste_avantages{
            margin-top: 35px;
        }
    }
    #cle_reussite{
        margin: 75px 0 0 0;
        .acc{
            padding: 61px 66px;
            strong{
                font-size: 41px;
            }
            span{
                font-size: 33px;
            }
        }
    }
}

@media (min-width: $break-lg) and (max-width: $break-xl){
    .produit_style{
        a.titre{
            font-size: 31px;
            line-height: 34px;
        }
    }
}